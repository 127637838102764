<template>
  <Toast />
  <ConfirmDialog :breakpoints="{ '1800px': '35vw', '1350px': '35vw', '960px': '50vw', '640px': '98vw' }"
    :style="{ width: '25vw' }"></ConfirmDialog>
  <div class="p-shadow p-bg-white p-container" style="width:100%;">

    <div class="p-d-flex p-jc-between">
      <span class="p-text-responsive">PAGOS</span>
    </div>

    <div class="p-grid p-col-12 p-mb-2 p-mt-1 p-mb-3">
      <!-- <span 
        class="tabview pointer" 
        @click="tab.link=true;tab.cuotas=false;tab.historial=false" 
        :class="{'tabview-active' : tab.link}">
          LINK DE PAGO
      </span> -->
      <span class="tabview pointer" @click="tab.link = false; tab.cuotas = true; tab.historial = false"
        :class="{ 'tabview-active': tab.cuotas }">
        CUOTAS
      </span>
      <span class="tabview pointer" @click="tab.link = false; tab.cuotas = false; tab.historial = true"
        :class="{ 'tabview-active': tab.historial }">
        HISTORIAL DE PAGOS
      </span>
    </div>

    <div class="">
      <!-- cuotas pendientes por pagar -->
      <div v-if="tab.cuotas" class="">
        <div v-if="lista.length > 0" class="p-grid">
          <div class="p-grid p-col-12 p-p-0">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-7">
              <Panel v-for="item in lista_cursos" :key="item" :header="item.nombre" class="p-mb-3">
                <div class="p-list-group p-mb-0">
                  <div class="p-list-group-item" v-for="cuota in item.cuotas" :key="cuota">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      <div class="p-d-flex p-flex-column p-ai-start p-jc-start">
                        <div>
                          <i :style="cuota.estado ? 'color:green;' : ''"
                            :class="{ 'pi pi-clock': !cuota.estado, 'pi pi-check-circle': cuota.estado, }"></i>&nbsp;
                          <span style="font-size:11pt;" class="p-text-uppercase">{{ cuota.cuota }}</span>
                        </div>
                        <small
                          :style="cuota.recargo > 0 && !cuota.estado && confi.aplica_recargo ? 'color:#ff4040;' : 'color:#6c757d;'"
                          class="p-mt-1">
                          <span>{{ cuota.fecha_limite }} <span
                              v-if="cuota.recargo > 0 && !cuota.estado && confi.aplica_recargo">(recargo)</span></span>
                        </small>
                      </div>
                      <div class="p-d-flex p-ai-start p-jc-between" style="width:80px;">
                        <div class="p-d-flex p-flex-column p-ai-start p-jc-start">
                          <span style="font-weight:500; font-size:13pt;" class="p-mr-2">{{ $format.usd(cuota.valor ==
                            null ? cuota.valor : cuota.valor)}}</span>
                          <small v-if="confi.aplica_recargo && cuota.recargo > 0 && !cuota.estado"
                            style="color:#ff4040;"> +{{ $format.usd((confi.porcentaje / 100) * cuota.valor) }}</small>
                        </div>
                        <Checkbox :id="cuota.codigo" @change="validar_cuotas(cuota.codigo)"
                          v-if="!cuota.estado && !cuota.es_recargo" v-model="seleccion_cuotas" name="cuotas"
                          :value="cuota.codigo" />
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-5">
              <Panel header="DETALLE DE PAGO" class="panel-padding">
                <template #icons v-if="confi.aplica_recargo">
                  <button class="p-panel-header-icon p-link" @click="$refs.op.toggle">
                    <span class="pi pi-exclamation-triangle"></span>
                  </button>
                  <OverlayPanel ref="op">
                    <p style="width:200px !important; text-align:justify;">Al exceder la fecha límite de pago se
                      producirá un recargo del {{ confi.porcentaje }}% sobre el valor de la cuota.</p>
                  </OverlayPanel>
                </template>
                <div v-if="detalle_pagar.length > 0">
                  <div class="p-d-flex p-flex-column">
                    <div v-for="item in detalle_pagar" :key="item" class="p-d-flex p-flex-column p-mb-3">
                      <span style="font-weight:500;">{{ $format.cut(item.nombre, 40) }}</span>
                      <div v-for="cuota in item.cuotas" :key="cuota" class="p-d-flex p-jc-between p-ml-2 p-mt-1"
                        style="font-size:12pt;">
                        <span>- {{ cuota.cuota }}</span>
                        <div class="p-d-flex p-jc-between p-ai-center" style="width:70px;">
                          <span style="font-weight:500;">{{ $format.usd(cuota.valor) }}</span>
                          &nbsp;<i v-if="!cuota.es_recargo" style="color:#B76024;" @click="quitar(cuota.codigo)"
                            class="pi pi-times pointer"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div class="p-d-flex p-jc-between p-ai-center">
                    <p class="p-my-0" style="font-weight:500; font-size:14pt; text-align:center;">TOTAL
                      {{ $format.usd(total_pagar) }}</p>
                    <Button label="PAGO UGB" @click="realizar_pago" :loading="pagar_is_loading" class="p-button-ugb"
                      style="width:200px;"></Button>
                  </div>
                </div>
                <div v-else>
                  <p class="text-muted" style="font-size:12pt; text-align:center;">Seleccione elementos de la lista</p>
                </div>
              </Panel>
            </div>
          </div>
        </div>
        <div v-if="lista_is_loading" class="p-col-12 p-p-0 p-d-flex p-jc-center">
          <p style="font-size:12pt; color:#424242;">&nbsp;<i class="pi pi-spinner pi-spin"></i> CARGANDO REGISTROS</p>
        </div>

        <div v-if="lista_is_loading == false && lista.length == 0" class="p-my-2">
          <p style="font-size:13pt; text-align:center;" class="text-muted">No tiene pagos pendientes</p>
        </div>
      </div>

      <!-- historial pagos realizados -->
      <div v-else-if="tab.historial" class="p-grid">
        <div v-if="pagos.length > 0" class="p-col-12 p-py-0">
          <div class="padre">
            <div class="hijo" v-for="x in pagos" :key="x">
              <div class="p-d-flex p-jc-between p-ai-center" style="min-height:40px;">
                <div>
                  <i v-if="!x.es_cuota" class="icon pi pi-check-circle"></i>
                  <i v-else @click="toggleThis(x.codigo)"
                    :class="{ 'pi-angle-right': !x.abierto, 'pi-angle-down': x.abierto }" class="icon pi pointer"></i>
                  <span style="font-size:11pt;">{{ x.curso }}</span>
                </div>
                <div class="p-d-flex p-flex-column p-ai-end p-jc-end" v-if="!x.es_cuota">
                  <div class="p-d-flex p-jc-end p-ai-center">
                    <div class="p-d-flex p-flex-column p-jc-end p-ai-end">
                      <span style="font-weight:500; font-size:12pt;">{{ $format.usd(x.valor) }}</span>
                      <span style="color:gray;">{{ x.fecha }}</span>
                    </div>
                    <div class="p-ml-2">
                      <Button v-if="x.valor == 0" icon="pi pi-print" disabled="disabled"
                        class="p-button-plain p-button-text"></Button>
                      <Button v-else icon="pi pi-print" @click="comprobante(x.codigo_pago)"
                        class="p-button-plain p-button-text"></Button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <span class="text-muted">({{ x.cuotas.filter(y => !y.recargo).length }} {{ x.cuotas.length == 1 ? 'Cuota'
                    :
                    'Cuotas'}})</span>
                </div>
              </div>
              <div class="padre" v-if="x.abierto">
                <div class="hijo" v-for="y in x.cuotas" :key="y" style="background:#f6f6f6;">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    <div>
                      <i class="pi pi-check-circle icon"></i>
                      <span>{{ y.recargo ? 'RECARGO ' : '' }}{{ y.cuota }}</span>
                    </div>
                    <div class="p-d-flex p-jc-end p-ai-center">
                      <div class="p-d-flex p-flex-column p-ai-end p-jc-end">
                        <span style="font-weight:500; font-size:12pt;">{{ $format.usd(y.valor) }}</span>
                        <span style="color:gray;">{{ y.fecha }}</span>
                      </div>
                      <div class="p-ml-2">
                        <Button icon="pi pi-print" label="Comprobante" @click="comprobante(y.codigo_pago)"
                          class="p-button-plain p-button-text"></Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="lista_is_loading" class="p-col-12 p-p-0 p-d-flex p-jc-center">
          <p style="font-size:12pt; color:#424242;">&nbsp;<i class="pi pi-spinner pi-spin"></i> CARGANDO REGISTROS</p>
        </div>
        <div v-if="lista_is_loading == false && pagos.length == 0" class="p-my-2 p-col-12 p-p-0 p-d-flex p-jc-center">
          <p style="font-size:13pt; text-align:center;" class="text-muted">No se han encontrado registros</p>
        </div>
      </div>


    </div>
  </div>
  <Dialog header="COMPROBANTE" @hide="urlPdf = ''" v-model:visible="modal_comprobante" :position="'top'"
    :style="{ width: '50vw' }" :draggable="false" :modal="true" closeOnEscape="true">
    <p v-if="comprobante_is_loading" class="p-text-center"><i class="pi pi-spin pi-spinner"></i>&nbsp;<span>CARGANDO
        COMPROBANTE</span></p>
    <object type="application/pdf" :data="urlPdf" style="width:100%; height:500px;"></object>
  </Dialog>
</template>

<style>
.panel-padding .p-panel-header {
  padding: 8px 17.5px !important;
}

.padre {
  margin: 10px 0px;
}

.hijo {
  padding: 10px 15px;
  border: 1px solid #e7e7e7;
  border-bottom: none;
}

.padre .hijo:last-child {
  border-bottom: 1px solid #e7e7e7;
}

.icon {
  padding: 3px 5px;
}

.tabview {
  padding: 10px 15px;
  color: #424242;
}

.tabview:hover {
  border-bottom: 2px solid #d7d7d7;
}

.tabview-active {
  color: #263680;
  border-bottom: 2px solid #263680 !important;
  background: #fff !important;
  font-weight: 500;
}
</style>



<script>
export default {
  data() {
    return {
      confi: { // datos de configuración
        arancel: 70,
        porcentaje: 15,
        aplica_recargo: true,
      },
      urlPdf: '',
      tab: {
        link: false,
        cuotas: true,
        historial: false
      },
      modal_comprobante: false,
      codigo_pago: 0,
      pagar_is_loading: false,
      seleccion_cuotas: [], // marca las cuotas pendientes que ha seleccionado para pagar
      lista_is_loading: false, // define si se activa el tab de cuotas pendientes
      historial_pagos: false, // define si se activa el tab de pagos realizados
      lista: [], // lista de cuotas pendientes por pagar
      pagos: [], // lista de pagos hechos
      lista_links: [],
    }
  },
  methods: {
    toggleThis(codigo) {
      this.pagos.forEach(x => {
        if (x.codigo == codigo) {
          x.abierto = !x.abierto;
        }
      });
    },
    async generar_comprobante(codigo) {
      this.modal_comprobante = true;
      this.comprobante_is_loading = true;
      await this.axios.get("/Cursos/ComprobantePago/" + codigo, { responseType: 'blob' })
        .then(async response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          this.blobToBase64(blob);
        })
        .catch(() => { })
        .then(() => { this.comprobante_is_loading = false; })
    },
    async comprobante(codigo) {
      this.modal_comprobante = true;
      this.comprobante_is_loading = true;
      await this.axios.get("/Cursos/Comprobante/" + codigo, { responseType: 'blob' })
        .then(async response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          this.blobToBase64(blob);
          console.log(response);
        })
        .catch(() => { })
        .then(() => { this.comprobante_is_loading = false; })
    },
    blobToBase64(blob) {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.urlPdf = reader.result;
      }
    },
    async traer_lista() {
      this.lista_is_loading = true;
      await this.axios.get("/Pagos")
        .then(r => {
          this.confi.arancel = r.data.conf[0].arancel;
          this.confi.porcentaje = r.data.conf[0].porcentaje;
          this.confi.aplica_recargo = r.data.conf[0].aplica_recargo;
          let cursos = [];
          r.data.cuotas.forEach(x => {
            if (!cursos.includes(x.codigo_curso)) cursos.push(x.codigo_curso);
          })
          let data = r.data.cuotas;
          let pagos = r.data.pagos;
          let p = [];
          cursos.forEach(y => {
            var i = 0;
            data.forEach(x => {
              if (y == x.codigo_curso) {
                i++;
                x.n = i;
                if ((x.estado == null || !x.estado) && x.recargo > 0) {
                  x.cuota_recargo = true;
                  x.valor_recargo = (x.valor * (this.confi.porcentaje / 100));
                }
                this.lista.push(x);
              }
            });
          })
          pagos.forEach(x => {
            if ((p.findIndex(y => y.codigo == x.codigo_curso && y.tipo == x.tipo)) < 0) {
              p.push({
                codigo: x.codigo_curso,
                codigo_pago: x.codigo_pago,
                curso: x.nombre,
                fecha: x.fecha,
                valor: x.valor,
                es_cuota: x.codigo_cuota == null ? false : true,
                abierto: x.codigo_curso == this.codigo_pago ? true : false,
                tipo: x.tipo,
                cuotas: x.codigo_cuota == null ? null : pagos.filter(y => y.codigo_curso == x.codigo_curso && y.tipo == x.tipo),
              })
            }
          })
          this.pagos = p;
          if (this.lista.length == 0) this.historial_pagos = true;
        })
        .catch(() => { })
        .then(() => { this.lista_is_loading = false; })
    },
    quitar(codigo) {
      let index = this.seleccion_cuotas.findIndex(x => x == codigo);
      this.seleccion_cuotas.splice(index, 1);
      this.lista_recargos.forEach(x => {
        let include = this.seleccion_cuotas.includes(x.padre);
        let notinclude = this.seleccion_cuotas.includes(x.codigo);
        if (!include && notinclude) {
          let index = this.seleccion_cuotas.findIndex(y => y == x.codigo);
          this.seleccion_cuotas.splice(index, 1);
        }
      })
      this.validar_cuotas(codigo);
    },
    realizar_pago() {
      this.$confirm.require({
        message: 'SE REALIZARÁ UN PAGO, ¿DESEA PROCEDER?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Realizar',
        rejectLabel: 'Cancelar',
        blockScroll: false,
        rejectClass: 'p-button-danger',
        accept: async () => {
          this.pagar_is_loading = true;
          await this.axios.post("/Pagos/Solicitud", this.solicitud_pago)
            .then(r => {
              window.location.href = process.env.VUE_APP_URLPOS + r.data.codigo_solicitud;
            })
            .catch(() => {
              this.$toast.add({ severity: 'error', summary: 'Aviso', detail: 'Ha ocurrido un problema, intentelo nuevamente más tarde', life: 3000 });
            })
            .then(() => {
              this.pagar_is_loading = false;
            });
        }
      });
    },
    validar_cuotas(codigo) {
      let e = !document.getElementById(codigo).checked;
      var cuotas = this.lista.slice();
      var codigo_curso = cuotas.filter(x => x.codigo == codigo)[0].codigo_curso;
      var cuotas_curso = cuotas.filter(x => x.codigo_curso == codigo_curso);
      var n = cuotas_curso.filter(x => x.codigo == codigo)[0].n;
      var lcuotas = [];
      cuotas_curso.forEach(x => lcuotas.push(x.codigo));
      lcuotas.forEach(x => {
        let i = this.seleccion_cuotas.findIndex(y => y == x);
        if (i >= 0) {
          this.seleccion_cuotas.splice(i, 1);
        }
      })
      for (var i = 1; i <= cuotas_curso.length; i++) {
        let index = cuotas_curso.findIndex(x => x.n == i);
        let numero = cuotas_curso[index].n;
        let codigo = cuotas_curso[index].codigo;
        if (e) {
          if (n >= numero) this.seleccion_cuotas.push(codigo);
        }
        else {
          if (n > numero) this.seleccion_cuotas.push(codigo);
        }
      }
    }
  },
  mounted() {
    this.traer_lista();
    if (this.$route.query.historial != undefined || this.$route.query.historial) {
      this.historial_pagos = true
      this.codigo_pago = this.$route.query.historial;
    }
    else {
      this.historial_pagos = false
    }
  },
  computed: {
    lista_cursos: function () {
      let c = [];
      let data = this.lista;
      for (let i = 0; i < data.length; i++) {
        if (c.findIndex(x => x.codigo == data[i]['codigo_curso']) == -1) {
          c.push({
            codigo: data[i]['codigo_curso'],
            nombre: data[i]['nombre_curso'],
            cuotas: data.filter(x => x.codigo_curso == data[i]['codigo_curso'])
          });
        }
      }
      return c
    },
    lista_pagar: function () {
      let lista = this.seleccion_cuotas;
      let cuotas = this.lista;
      let data = [];
      for (let i = 0; i < lista.length; i++) {
        let arr = cuotas.filter(x => x.codigo == lista[i])[0];
        data.push(arr);
        if (arr.cuota_recargo == true && this.confi.aplica_recargo) {
          data.push({
            codigo: arr.codigo,
            codigo_curso: arr.codigo_curso,
            codigo_cuota: arr.codigo_cuota,
            codigo_arancel: arr.codigo_arancel,
            nombre_curso: arr.nombre_curso,
            cuota: arr.cuota + ' (recargo)',
            valor: arr.valor_recargo,
            fecha_limite: arr.fecha_limite,
            recargo: arr.recargo,
            cuota_recargo: arr.cuota_recargo,
            valor_recargo: arr.valor_recargo,
            es_recargo: true,
          })
        }
      }
      return data;
    },
    lista_recargos: function () {
      return this.lista.filter(x => x.padre != undefined);
    },
    detalle_pagar: function () {
      let c = [];
      let data = this.lista_pagar;
      for (let i = 0; i < data.length; i++) {
        if (c.findIndex(x => x.codigo == data[i]['codigo_curso']) == -1) {
          c.push({
            codigo: data[i]['codigo_curso'],
            nombre: data[i]['nombre_curso'],
            cuotas: data.filter(x => x.codigo_curso == data[i]['codigo_curso']),
          });
        }
      }
      return c
    },
    solicitud_pago: function () {
      var detalle = [];
      this.seleccion_cuotas.forEach(x => {
        this.lista_pagar.forEach(y => {
          if (x == y.codigo) {
            detalle.push({
              codigo_curso: y.codigo_curso,
              codigo_cuota: y.codigo_cuota,
              codigo_arancel: y.codigo_arancel,
              precio: y.valor,
              recargo: y.es_recargo == undefined ? false : true,
            });
          }
        })
      })

      var solicitud = {
        descripcion: "PAGO DE CUOTAS",
        precio_total: this.total_pagar,
        detalle: detalle,
      };
      return solicitud;
    },
    total_pagar: function () {
      let data = this.detalle_pagar;
      let c = 0;
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].cuotas.length; j++) {
          c += data[i].cuotas[j].valor;
        }
      }
      return c;
    },
  },
  watch: {
    seleccion_cuotas: function () {
      let hijos = this.lista_recargos;
      hijos.forEach(x => {
        let include = this.seleccion_cuotas.includes(x.padre);
        let notinclude = this.seleccion_cuotas.includes(x.codigo);
        if (include && !notinclude) {
          this.seleccion_cuotas.push(x.codigo)
        }
        if (!include && notinclude) {
          let index = this.seleccion_cuotas.findIndex(y => y == x.codigo);
          this.seleccion_cuotas.splice(index, 1);
        }
      })
    }
  },
}
</script>
s